<template>
  <div id="mian">
    <ContainerList
        ref="container"
        :page-size="pageSize"
        :page-current.sync="pageNo"
        :page-count="totalNum"
        @page-change="changePageNo"
        @page-size-change="changePageSize">
        <div slot="header">
          <div class="con_from">
            <div class="lineP">
              <div class="item">
                <i>用户名</i>
                <Input v-model="filterData.keyword" suffix="ios-search" size="large" maxlength="128" placeholder="用户名/姓名" />
              </div>
              <div class="item">
                <i>状态</i>
                <Select v-model="filterData.enabledStatus" size="large">
                  <Option v-for="(item, idx) in enabledStatusOptions" :value="item.value" :key="idx">{{ item.label }}</Option>
                </Select>
              </div>
              <div class="itembtn">
                <Button class="searchBt" @click="getDataByFilter" style="margin-left:20px;">查询</Button>
                <LevelButton btnTitle="新增员工" :auth="'BASIC:SYSTEM:EMPLOYEES/ADD'" size="bigger" @confirm="addShow"></LevelButton>
              </div>
            </div>
          </div>
        </div>
        <Table row-key
          class="tab1"
          :columns="tableColumns"
          :data="tableData"
          :loading="isLoading">
          <template slot="enabledStatus" slot-scope="{ row }">
            <p v-if="OFFON_EDIT !== 'BASIC:SYSTEM:EMPLOYEES/EDIT'"> {{ row.enabledStatus==true ? "已开启" : "已关闭" }}</p>
            <p v-if="OFFON_EDIT == 'BASIC:SYSTEM:EMPLOYEES/EDIT'" v-auth="'BASIC:SYSTEM:EMPLOYEES/EDIT'">
              <i-switch v-model="row.enabledStatus" true-color="#409EFF" @on-change="setStatus(row)"></i-switch>
            </p>
          </template>
          <template slot="action" slot-scope="{ row }">
            <p>
              <button v-auth="'BASIC:SYSTEM:EMPLOYEES/EDIT'" class="lianjie" @click="editUser(row)">编辑</button>
              <LevelButton btnTitle="删除" :auth="'BASIC:SYSTEM:EMPLOYEES/DELETE'" @confirm="delUser(row)"></LevelButton>
            </p>
          </template>
        </Table>
    </ContainerList>
    <Modal v-model="addUserShow" :title="editShow === null ? '新增员工' : '编辑员工'" width="500px" closable @on-cancel="addUserShow = false">
      <ul class="overAccount_ul">
        <div class="item">
          <div class="title"><i>*</i>用户名</div>
            <div v-if="formData.userId == 0">
              <Input
                v-model="formData.username"
                placeholder="输入角色名称"
                style="width: 120px; margin-right: 5px"
                size="large"
                tabindex="1">
              </Input>@<span class="username hidden1">{{ userInfo.agentId }}</span>
          </div>
          <div style="line-height: 40px; margin-bottom: 8px" v-else>
            {{ formData.username }}
          </div>
        </div>
        <div class="item">
          <div class="title"><i>*</i>姓名</div>
            <Input
              v-model="formData.realname"
              placeholder="输入姓名"
              size="large"
              tabindex="2">
            </Input>             
        </div>
        <div class="item">
          <div class="title"><i>*</i>手机号码</div>
            <Input
              v-model="formData.phoneNumber"
              maxlength="11"
              placeholder="输入手机号码"
              size="large"
              tabindex="3">
            </Input>
        </div>

        <div class="item">
          <div class="title"><i v-if="editShow===null">*</i>登录密码</div>
          <Input
              v-model="formData.password"
              type="password"
              password
              :placeholder="
                formData.userId == 0 ? '输入登录密码' : '输入表示修改密码'
              "
              tabindex="4">
          </Input>
        </div>
        <div class="item">
          <div class="title"><i>*</i>角色分配</div>
          <Select v-model="formData.roleId" size="large" tabindex="5" placeholder="选择角色">
            <Option v-for="(v, i) in roleDrop" :value="v.roleId" :key="i">{{ v.roleName }}</Option>
          </Select>
        </div>
        <div class="item"></div>
      </ul>
      <span slot="footer" class="dialog-footer">
        <Button class="exitBt" @click="addUserShow = false">取 消</Button>
        <!-- <Button class="exitBt" @click="onSubmit">确 定</Button> -->
        <LevelButton v-show="editShow === null" btnTitle="确 定" :buttonFlag="buttonFlag" size="middle" :auth="'BASIC:SYSTEM:EMPLOYEES/ADD'" @confirm="onSubmit" @close="addUserShow = false"></LevelButton>
        <LevelButton v-show="editShow !== null" btnTitle="确 定" :buttonFlag="buttonFlag" size="middle" :auth="'BASIC:SYSTEM:EMPLOYEES/EDIT'" @confirm="onSubmit" @close="addUserShow = false"></LevelButton>
      </span>
    </Modal>
  </div>
</template>
<script>
import {
  getRoles,
  getUsers,
  setUsers,
  setUsersStatus,
  delUsers,
} from "@/api/management/index.js";
import ContainerList from '@/components/ContainerList/index'
import tableMixin from '@/mixins/tableMixin'
import {getValidatecode} from "@/api/common.js"
import { mapState, mapMutations } from "vuex";
import md5 from "js-md5";
export default {
  components: { ContainerList },
  mixins: [ tableMixin ],

  data() {
    return {
      addUserShow: false,
      totalNum: 0,
      tableData: [],
      roleDrop: [],
      formData: {
        userId: 0,
        username: "",
        realname: "",
        phoneNumber: "",
        roleId: "",
        password: "",
      },
      userInfo: "",
      editShow: null,
      OFFON_EDIT: 'BASIC:SYSTEM:EMPLOYEES/EDIT',
      buttonFlag: false, //防止重复点击

      timeOut: 0,
      codeShow: "",
      editCodeShow:false,
      
      enabledStatusOptions: [
        { label: '全部', value: -1 },
        { label: '正常', value: 1 },
        { label: '冻结', value: 0 }
      ],

      pageNo: 1,
      pageSize: 10,
      filterData: {
        keyword: "",
        enabledStatus: -1
      },
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
      isLoading: false,
      tableApi: getUsers,
      tableKey: 'users',
      tableColumns: [
        { title: '用户名', key: 'username', align: 'center' },
        { title: '姓名', key: 'realname', align: 'center' },
        { title: '手机号', key: 'phoneNumber', align: 'center' },
        { title: '角色分配', key: 'roleName', align: 'center' },
        { title: '状态', key: 'enabledStatus', align: 'center', slot: 'enabledStatus' },
        { title: '操作', key: 'username', align: 'center', slot: 'action' }
      ],
      tableData: [],
      totalNum: 0,
      md5,
    };
  },
  computed: {
    ...mapState({
      staffQueryDetail: "system_staffQuery",
    }),
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.staffQueryDetail) {
      this.filterData = this.staffQueryDetail.query;
    }
    this.getDataByFilter()
    this.getDrop();
    this.listJurisdiction();
  },
  beforeDestroy() {
    let data = {
      query: this.filterData,
    };
    this.setStaffQuery(data);
  },
  methods: {
    ...mapMutations({
      setStaffQuery: "system_setStaffQuery",
    }),
    afterResponse (list, data) {
      this.totalNum = data.totalNum || 0
      return list
    },
    // 请求前钩子
    beforeRequest (params) {
      if (params.enabledStatus !== -1) {
        params.enabledStatus = Boolean(params.enabledStatus)
      }
      return params
    },
    // 获取下拉
    getDrop() {
      getRoles().then((res) => {
        if (res) {
          this.roleDrop = res.resultData;
        }
      });
    },
    addShow() {
      this.formData = {
        userId: 0,
        username: "",
        realname: "",
        phoneNumber: "",
        roleId: "",
        password: "",
      };
      this.editShow = null;
      this.addUserShow = true;
      this.editCodeShow = false;
    },
    // 提交
    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.formData));
      let passwordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if (!data.username) {
        this.$Message.error("用户名必填");
        return;
      }
      if (!data.realname) {
        this.$Message.error("姓名必填");
        return;
      }
      if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(data.phoneNumber)) {
        this.$Message.error("请正确填写手机号码");
        return;
      }
      if (data.userId == 0) {
        if (!data.password) {
          return this.$Message.error("登录密码必填");
        } else if (
          !passwordTest.test(
            data.password
          )
        ) {
          return this.$Message.error(
            "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位及以上"
          );
        }
        data.password = md5(data.password);
      } else {
        if (data.password) {
          // 正则验证密码复杂度
          if (
            !passwordTest.test(
              data.password
            )
          ) {
            return this.$Message.error(
              "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位及以上"
            );
          }
          data.password = md5(data.password);
        }
      }
      if (!data.roleId) {
        this.$Message.error("角色必选");
        return;
      }
      this.buttonFlag = true;
      setUsers(data).then((res) => {
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000); 
        if (res.resultStatus) {
          this.$Message.success("保存成功");
          this.getDataByFilter();
          this.addUserShow = false; 
          this.timeOut = 0;
        }
      });
    },
    // 修改状态
    setStatus(value) {
      let data = {
        userId: value.userId,
        enabledStatus: value.enabledStatus,
      };
      setUsersStatus(data).then((res) => {
        if (!res) {
          value.enabledStatus = value.enabledStatus ? false : true;
        }
      });
    },
    // 修改
    editUser(value) {
      this.formData = JSON.parse(JSON.stringify(value));
      this.editShow = true;
      this.addUserShow = true;
      this.editCodeShow = false;
    },
    //删除员工
    delUser(value) {
      this.$confirm({
        isShow: true,
        message: '此操作将删除该用户, 是否继续?',
      }).then(() => {
        delUsers(value.userId).then((res) => {
          if (res) {
            this.getDataByFilter()
            this.$Message.success('删除成功')
          }
        })
      }).catch(() => {
        this.$Message.warning('已取消删除')
      })
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
      const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources
      if (_resources=='ALL') {
        this.OFFON_EDIT = 'BASIC:SYSTEM:EMPLOYEES/EDIT'
      } else {
        if (_resources.split(';').indexOf('BASIC:SYSTEM:EMPLOYEES/EDIT') !== -1 ) {
          this.OFFON_EDIT = 'BASIC:SYSTEM:EMPLOYEES/EDIT'
        } else {
          this.OFFON_EDIT = ""
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tab1 {
    min-height: 650px !important;
}
.addDetailBt:hover {
  background-color: #fff;
  color: var(--themeColor);
}

.addDetailBt {
  float: right;
  width: 90px;
  height: 36px;
  margin-left: 20px;
  border-color: #d7dae2;
  padding: 0;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;  
  padding: 0px 10px;
  box-sizing: border-box;   
}

.overAccount_ul::after{
  content: "";
  clear: both;
}

.overAccount_ul .title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  padding-left: 0px;
}

.overAccount_ul .title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul /deep/ .ivu-input,
.overAccount_ul /deep/ .ivu-select {
  margin-bottom: 7px;
}

.overAccount_ul .item {
  float: left;
  width: 210px;
}
.overAccount_ul .item.itemCode{
  width: 100% !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.overAccount_ul .item .getCode{
  width: 200px;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-bottom: 7px;
    color: var(--themeColor);
}
.username {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}
</style>
